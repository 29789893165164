const apiUrl = 'https://wuotp4sa2m.execute-api.ap-south-1.amazonaws.com'; // TODO update after sls deployment
const banksLogosUrl =
  'https://aph-ind-dev-banks-logos.s3.ap-south-1.amazonaws.com';
const testMerchant = '63d1296686f42c2c5b1a101e';
const widgetUrl = 'https://d1yfrlzpsutzdo.cloudfront.net';

module.exports = {
  apiUrl,
  banksLogosUrl,
  testMerchant,
  widgetUrl
};
