import App from './App.svelte';

const { time, version } = APP_BUILD

console.log(`App has been build at ${new Date(time).toLocaleString()} from version: ${version}`);

const app = new App({
  target: document.body,
});

export default app;