<script>
  import CloseCircle from 'svelte-material-icons/CloseCircle.svelte';
  import { general as generalStrings } from '@aph-locale/resources/strings.js';

  export let message = '';
  const color = '#b71c1c';
</script>

<CloseCircle width="100px" height="100px" {color} />
<h3 class="heading" style="color: {color}">
  {generalStrings.transactionFailure}
</h3>
<p class="message">
  {message}
</p>

<style>
  .heading {
    font-size: 2rem;
    text-align: center;
  }

  .message {
    font-size: 1.3rem;
    text-align: center;
    padding: 0 2rem;
    overflow-wrap: break-word;
  }
</style>
