<script>
  import Card from '../components/Card.svelte';
  import PriceContainer from './PriceContainer.svelte';
  import SupportText from '../components/SupportText.svelte';
  import TransactionInfo from './TransactionInfo.svelte';

  import { transaction } from '../stores/transaction.store';
  import { selectedBank } from '../stores/selected-bank.store';

  $: initial = $selectedBank.name === '';
</script>

<SupportText alignRight text={`ID: ${$transaction.id}`} />
<Card>
  <div class:initial class="info-card">
    <PriceContainer />
    <TransactionInfo />
  </div>
</Card>

<style>
  .info-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem;
  }

  .initial {
    background-color: var(--blue);
    border-radius: inherit;
    color: white;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.07),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.05),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.03);
  }

  .initial :global(.text) {
    color: white;
  }
  .initial :global(.label) {
    color: var(--gray-light);
  }
</style>
