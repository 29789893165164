import { futures } from '@aph-locale/resources/const.js';

function isFuture(bank) {
  if (bank && bank.name) {
    return futures.includes(bank.name);
  }
  return false;
}

export { isFuture };
