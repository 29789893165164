<script>
  import Price from '../components/Price.svelte';
  import PriceWithFee from '../components/PriceWithFee.svelte';

  import { TRANSACTION_FLOW } from '@aph-locale/resources/const.js';

  import { manualTransaction } from '../stores/manual-transaction.store';
  import { transaction } from '../stores/transaction.store';

  $: originalAmount = $transaction.originalAmount;
  $: price = $manualTransaction.transaction
    ? $manualTransaction.transaction.amount
    : $transaction.amount;
  $: transactionFee = $transaction.transactionFee;
  $: transactionFlow = $transaction.transactionFlow;
</script>

<div>
  {#if transactionFlow === TRANSACTION_FLOW.COMISSION}
    <PriceWithFee {price} {transactionFee} {originalAmount} />
  {:else}
    <Price {price} />
  {/if}
</div>

<style>
</style>
