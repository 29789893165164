<script>
  import SelectedBankInfo from './SelectedBankInfo.svelte';

  import { transaction } from '../stores/transaction.store';
  import { selectedBank } from '../stores/selected-bank.store';
</script>

<div class="transactionDetails">
  {#if $selectedBank.name !== ''}
    <SelectedBankInfo />
  {/if}
  <h3 data-private class="surname-name">{`${$transaction.surname} ${$transaction.name}`}</h3>
</div>

<style>
  .transactionDetails {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.3rem;
  }

  .surname-name {
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
  }
</style>
