import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumber: yup
    .string()
    .min(1, errors.incorrectLength)
    .max(65, errors.incorrectLength)
    .required(errors.fieldRequired),

  password: yup.string().max(128, errors.incorrectLength).required(errors.fieldRequired),
});

const otpInitial = {
  verificationCode: '',
};

const otpShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const customInitialValues = {
  code1: '',
  code2: '',
  code3: '',
};

const customShape = yup.object().shape({
  code1: yup
    .string()
    .length(2, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  code2: yup
    .string()
    .length(2, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  code3: yup
    .string()
    .length(2, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export { loginInitialValues, loginShape, otpInitial, otpShape, customInitialValues, customShape };
