export { isLoading, isLogin, isVerification, isResult };

function isLoading(state) {
  return state === 'loading';
}
function isLogin(state) {
  return state === 'login' || state === 'loginRetry';
}
function isVerification(state) {
  return (
    state.includes('Retry') ||
    state === 'otp' ||
    state === 'emailCode' ||
    state === 'smsCode' ||
    state === 'date' ||
    state === 'custom' ||
    state === 'set' ||
    state === 'hiddenChar' ||
    state === 'telephone' ||
    state === 'appAuth' ||
    state === 'qrCode'
  );
}

function isResult(state) {
  return state === 'transactionResult';
}
