import { errors as errorStrings } from './strings';

export const errors = {
  loginRetry: { type: 'login', message: errorStrings.incorrectCredentials },
  loginUnknown: { type: 'general', message: errorStrings.internalError },
  Unknown: { type: 'general', message: errorStrings.internalError },
  InternalError: { type: 'general', message: errorStrings.internalError },
  otpRetry: { type: 'verification', message: errorStrings.invalidOtpCode },
  hiddenCharRetry: { type: 'verification', message: errorStrings.invalidOtpCode },
  smsCodeRetry: { type: 'verification', message: errorStrings.invalidSMSCode },
  setRetry: { type: 'verification', message: errorStrings.invalidData },
  NoMoney: { type: 'general', message: errorStrings.noMoney },
  loginNoMoney: { type: 'general', message: errorStrings.noMoney },
  dateRetry: { type: 'verification', message: errorStrings.invalidData },
  customRetry: { type: 'verification', message: errorStrings.invalidData },
  connectionLost: { type: 'general', message: errorStrings.serviceUnavailable },
  LimitExceeded: { type: 'general', message: errorStrings.limitExceeded },
  MinTransferLimit: { type: 'general', message: errorStrings.minTransferLimit },
  MaxTransferLimit: { type: 'general', message: errorStrings.maxTransferLimit },
  OnlyUSDTransfers: { type: 'general', message: errorStrings.onlyUSDTransfers },
  AccountLimitExceeded: { type: 'general', message: errorStrings.accountLimitExceeded },
  DailyLimitWillBeExceeded: { type: 'general', message: errorStrings.dailyLimitWillBeExceeded },
};
