import { apiUrl } from '@aph-config';
const { version } = APP_BUILD;
const URL = `${apiUrl}/api`;
const TIMEOUT = 15 * 60 * 1000; // 15 min
const DELAY = 1000;
const retries = Math.ceil(TIMEOUT / DELAY);

const api = {
  createTransaction,
  startTrx,
  getCurrentState,
  postCode,
  getTransactionResult,
  startTestTransaction,
  startManualTransaction,
  startFailedTransaction,
};

export default api;

export async function getBanks(merchantId) {
  return fetch(`${URL}/banks/${merchantId}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .then((res) => res.banks);
}

export function createTransaction(transactionDetails) {
  return fetch(`${URL}/transaction`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-aphwidget-version': `${version}`,
    },
    body: JSON.stringify({
      ...transactionDetails,
    }),
  })
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = (data && data.current) || res.status;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((e) => {
      throw e;
    });
}

export function startTrx(id, credentials, bankName) {
  return fetch(`${URL}/transaction/${id}/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-aphwidget-version': `${version}`,
    },
    body: JSON.stringify({ credentials, bankName }),
  });
}

export function getCurrentState(id) {
  let currentTry = 0;
  return new Promise((resolve, reject) => {
    const requestInterval = setInterval(() => {
      currentTry += 1;
      if (currentTry >= retries) {
        clearInterval(requestInterval);
        reject(new Error('Timeout'));
      }

      fetch(`${URL}/transaction/${id}/state`)
        .then((response) => response.json())
        .then((response) => {
          if (response.current !== '') {
            clearInterval(requestInterval);
            resolve({
              current: response.current,
              customQuestion: response.customQuestion,
              transactionResult: response.transactionResult,
            });
          }
        })
        .catch((e) => {
          clearInterval(requestInterval);
          reject(e);
        });
    }, DELAY);
  });
}

export function postCode(id, codeType, code) {
  return fetch(`${URL}/transaction/${id}/state`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      [codeType]: code,
    }),
  });
}

export async function getTransactionResult(id) {
  const response = await fetch(`${URL}/transaction/${id}/state`)
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = (data && data.current) || res.status;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((e) => {
      throw e;
    });
  return response.transactionResult;
}

export function startTestTransaction(id) {
  return fetch(`${URL}/transaction/${id}/test-start`, {
    method: 'POST',
  });
}

export async function startManualTransaction(id) {
  return fetch(`${URL}/transaction/${id}/start-manual`, {
    method: 'POST',
  })
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        return Promise.reject(data.current);
      }

      return data;
    })
    .catch((e) => {
      throw e;
    });
}

export function startFailedTransaction(id) {
  return fetch(`${URL}/transaction/${id}/test-failed`, {
    method: 'POST',
  });
}
