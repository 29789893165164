<script>
  import { onMount } from 'svelte';

  import Status from '../../../../containers/Status/Status.svelte';
  import Loading from '../../../../components/Loading.svelte';
  import api from '../../../../api/';
  import { transaction } from '../../../../stores/transaction.store';

  let loading = true;

  onMount(async () => {
    await api.startTestTransaction($transaction.id);
    loading = false;
  });
</script>

{#if loading}
  <Loading />
{:else}
  <Status type="Success" message="Success" />
{/if}
