<script>
  import FeatureSearchOutline from 'svelte-material-icons/FeatureSearchOutline.svelte';

  import { setSearchQuery } from '../stores/banks.store';

  import { general as generalStrings } from '@aph-locale/resources/strings.js';

  let filter = '';

  $: setSearchQuery(filter);
</script>

<div class="container">
  <label for="search">
    <div class="input-container">
      <div class="icon">
        <FeatureSearchOutline width={20} height={20} />
      </div>
      <input
        type="text"
        name="search"
        placeholder={generalStrings.searchInputPlaceholder}
        bind:value={filter}
        class="input"
      />
    </div>
  </label>
</div>

<style>
  .container {
    border: none;
    border-radius: 4px;
    margin: 0 auto;
    background-color: white;
    width: 100%;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }
  .input-container {
    position: relative;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
  }

  .icon {
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(-50%);
  }

  .input {
    width: 100%;
    padding: 0.5rem 2.5rem;
    border: none;
    color: var(--gray-dark);
    font-family: 'Zen Kaku Gothic New', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    outline: none;
  }
</style>
