<script>
  import BankLogo from '../components/BankLogo.svelte';
  import LabeledText from '../components/LabeledText.svelte';

  import { selectedBank } from '../stores/selected-bank.store';
</script>

<div class="bank-info">
  <BankLogo bankName={$selectedBank.name} />
  <LabeledText alignRight text={$selectedBank.nameJapanese} />
</div>

<style>
  .bank-info {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
  }
</style>
