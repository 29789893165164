export const verificationText = {
  otp: {
    title: 'One-time password',
    placeholder: 'Enter one-time password',
  },
  emailCode: {
    title: 'Code sent by EMAIL',
    placeholder: 'Enter email code',
  },
  smsCode: {
    title: 'Code sent by SMS',
    placeholder: 'Enter sms code',
  },
  custom: {
    title: 'Question',
  },
  hiddenChar: {
    title: '',
    placeholder: '',
  },
  telephone: {
    title: 'Telephone number',
    placeholder: '',
  },
  appAuth: {
    title: 'Software token password',
    placeholder: 'Enter token password',
  },
};

export const errors = {
  incorrectCredentials: 'Your login information is incorrect.',
  invalidOtpCode: 'Invalid One Time Password (OTP) code.',
  hiddenCharRetry: 'There is an error in the input of the one-time password.',
  invalidData: 'Invalid data. retry',
  invalidSMSCode: 'Invalid sms code',
  noMoney: 'Insufficient funds.',
  serviceUnavailable: 'Service temporarily unavailable',
  internalError: 'Your transaction could not be completed.',
  limitExceeded: 'Exceeded daily transaction limit',
  minTransferLimit: 'Transfer amount is below the minimum amount',
  maxTransferLimit: 'Remittance amount exceeds maximum amount',
  onlyUSDTransfers: 'Currently, only USD-denominated transactions are possible',
  timeout: 'Timeout',
  fieldRequired: 'Required field',
  accountLimitExceeded: 'The transaction amount exceeds the daily usage limit.',
  dailyLimitWillBeExceeded:
    'The entered amount cannot be transferred because it exceeds the transfer limit per day.',
};

export const general = {
  amountInCurrency: 'Total amount in Indian rupee',
  currency: '₹',
  selectBank: 'Please select a bank',
  bank: 'Bank',
  bankSelection: 'Bank selection',
  back: 'Choose another bank',
  backToLogin: 'Back',
  next: 'Next',
  amount: 'Money amount',
  transaction: 'Transaction',
  acceptSelection: 'Accept',
  transactionFailure: 'Failed!',
  transactionSuccess: 'Success!',
  transactionSuccessInfo: 'Transaction request completed successfully.',
  transferConfrimation: 'Confirm',
  bankDisabled: 'Suspended due to maintenance work by the bank.',
  depositAmount: 'Deposit amount',
  transactionFee: 'Transaction Fee',
  totalAmount: 'Total amount',
  searchInputPlaceholder: 'Find a bank',
  retryTrx: 'Retry',
};

export const icici = {
  userId: 'User ID',
  password: 'Password',
  codeCardTitle: 'Grid Card',
  codeCardInfo:
    'Please enter the following digits of your Debit Grid - this will be on the back of your card',
};
