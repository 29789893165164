<script>
  export let text;
  export let alignRight = false;
</script>

<div class:alignRight class="container">
  <span class="text">{text}</span>
</div>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: -0.7rem;
    padding-left: 0.2rem;
  }

  .alignRight {
    justify-content: flex-end;
    text-align: right;
  }

  .text {
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: var(--gray-text);
  }
</style>
