import { readable } from 'svelte/store';
import { createTransaction } from '../api';
import { getFrameData } from '../utils/locationUtils';
import { setError } from './error.store';

export const transaction = readable({}, async function start(set) {
  const frameData = getFrameData();
  set({ name: frameData.name, surname: frameData.surname, id: '', amount: '', backgroundUrl: '' }); // initial values
  try {
    const transactionDetails = await createTransaction(frameData);
    Object.keys(transactionDetails.priorities).forEach(
      (key) => (transactionDetails.priorities[key] = +transactionDetails.priorities[key]),
    );
    set(transactionDetails);
  } catch (e) {
    setError(e);
  }

  return function stop() {};
});
