<script>
  import { banksLogosUrl } from '@aph-config';

  export let bankName;

  const src = `${banksLogosUrl}/${bankName}.svg`;
</script>

<img {src} alt="{bankName} logo" />

<style>
  img {
    max-height: 50px;
    max-width: 100px;
  }

  @media (max-width: 550px) {
    img {
      max-height: 20px;
      max-width: 75px;
    }
  }
</style>
