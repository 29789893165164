function getFrameData() {
  const raw = window.location.hash.slice(1);
  const parsedRaw = raw.split('&');

  const searchObject = parsedRaw.reduce((prev, curr) => {
    const [key, value] = curr.split('=');
    if (key === 'email') {
      return { ...prev, [key]: encodeURIComponent(value) };
    }
    return { ...prev, [key]: value };
  }, {});

  const result = {};
  const searchParams = new URLSearchParams(searchObject);

  for (const [key, value] of searchParams) {
    result[key] = decodeURIComponent(value);
  }

  const { nonce, email, name, surname, merchant, amount, currency } = result;

  return {
    nonce,
    email,
    name,
    surname,
    merchant_id: merchant,
    authorized_amount: amount,
    currency,
    bank_code: result.bank_code, // optional
  };
}

function isShowAll() {
  const query = window.location.search;
  const searchParams = new URLSearchParams(query);
  return searchParams.has('showall');
}

export { getFrameData, isShowAll };
export default getFrameData;
