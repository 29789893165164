import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { timezoneString } from '@aph-locale/resources/const.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

function isUnderMaintenancePeriod(bank) {
  const maintenanceEnabled = bank.maintenance?.enabled ?? false;
  const startDate = bank.maintenance?.startDate;
  const endDate = bank.maintenance?.endDate;

  if (!maintenanceEnabled) {
    return false;
  }

  if (maintenanceEnabled && !startDate && !endDate) {
    return true;
  }

  const today = dayjs();
  const currentLocaleDate = today.tz(timezoneString);

  return isWithinRange(startDate, endDate, currentLocaleDate);
}

function isWithinRange(startDate, endDate, date) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return date.isBetween(start, end);
}

export { isUnderMaintenancePeriod };
export default isUnderMaintenancePeriod;
