import { derived } from 'svelte/store';
import { saveBankToLocalStore, getPreselectedBankName } from '../utils/preselectBankUtils';
import { banks } from './banks.store';
import { transaction } from './transaction.store';

const initialSelectedBank = {
  name: '',
  nameJapanese: '',
  nameEnglish: '',
  loading: true,
};

let setBank; // Forced derived store to become writable by caching set function here
let bankWasPreselected = false; // Singleton

const selectedBank = derived([transaction, banks], setPreselectedBank, initialSelectedBank);

function setPreselectedBank([$transaction, $banks], set) {
  setBank = set;
  if ($transaction.id !== '' && $banks.length > 0) {
    let preselectedBankName;
    if (bankWasPreselected === false) {
      preselectedBankName = getPreselectedBankName($transaction, $banks);
      bankWasPreselected = true;
    }
    if (preselectedBankName && preselectedBankName !== 'manual') {
      const preselectedBank = $banks.find((bank) => bank.name === preselectedBankName);
      if (preselectedBank) {
        preselectedBank.maintenance.enabled ? resetSelectedBank() : set(preselectedBank);
      }
    } else {
      set({
        ...initialSelectedBank,
        loading: false,
      });
    }
  }
}

function setSelectedBank(bank) {
  setBank(bank);
  saveBankToLocalStore(bank.name);
}

function resetSelectedBank() {
  setBank({ name: '', nameJapanese: '', nameEnglish: '', loading: false });
}

export { selectedBank, setSelectedBank, resetSelectedBank };
export default selectedBank;
