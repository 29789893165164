<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';

  import { transaction } from '@src/stores/transaction.store';
  import { selectedBank } from '@src/stores/selected-bank.store';
  import { localError } from '@src/stores/error.store';
  import { startProgress, progress } from '@src/stores/transaction-progress.store';

  import { startTrx } from '@src/api';

  import Input from '@src/components/Input.svelte';
  import LoginButtonsGroup from '@src/containers/LoginButtonsGroup.svelte';

  import { icici as bankStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return startTrx(
          $transaction.id,
          {
            accountNumber: submittedForm.accountNumber,
            password: submittedForm.password,
          },
          $selectedBank.name,
        ).then((res) => {
          console.log('res', res);
          if (res?.ok === false) {
            $progress = 0;
            setError(errorsConst.Unknown);
          } else {
            startProgress();
            console.log('dispatching submit evenet');
            dispatch('loginSubmit');
          }
        });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div class="account-container" in:fly={{ x: -200, duration: 300 }}>
    <Input
      label={bankStrings.userId}
      id="accountNumber"
      type="text"
      name="accountNumber"
      placeholder=""
      bind:value={$form.accountNumber}
      error={$errors.accountNumber}
      touched={$touched.accountNumber}
      {handleChange}
    />
  </div>
  <div class="password-container">
    <Input
      label={bankStrings.password}
      id="password"
      type="password"
      name="password"
      placeholder=""
      bind:value={$form.password}
      error={$errors.password}
      touched={$touched.password}
      {handleChange}
    />
  </div>
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <LoginButtonsGroup on:back />
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .account-container {
    width: 100%;
  }
  .password-container {
    width: 100%;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .error {
    color: red;
  }
</style>
